export function saveFile(response) {
  if (response.data) {
    let data = fromBinary(response.data);
    const BOM = '\uFEFF';
    let file = new Blob([BOM + data], {type: 'text/csv;charset=utf-8'});
    let url = URL.createObjectURL(file);
    let anchor = document.createElement('a');
    anchor.setAttribute('href', url);
    anchor.setAttribute('download', response.headers.filename);
    anchor.click();
    window.URL.revokeObjectURL(url);
  }
}

function fromBinary(str) {
  try {
    return decodeURIComponent(str.split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  } catch (e) {
    return str;
  }
}
